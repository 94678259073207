<script>
    import { goto } from '$app/navigation';
    import Card from './Card.svelte';
    import PurchasePaddle from './PurchasePaddle.svelte';
    import { format_price } from './utils/format_price';
    import ProductIcon from './ProductIcon.svelte';

    let className = '';
    export { className as class };

    export let courses = [];
    export let h_level = 2;

    export let horizontal = false;
    export let price_only = false;

</script>

{#each courses as course}
    <Card
        clickable
        class="flex {horizontal ? 'flex-row gap-4 items-center justify-between' : 'flex-col'} {className}"
        on:click={() => goto(`/courses/${course.slug}`)}
    >
        <ProductIcon
            product={course}
            class="mx-auto my-4 {horizontal ? 'max-sm:hidden' : ''}"
        />


        <div class="{horizontal ? 'flex-1 w-full' : ''}">
            <svelte:element
                this={`h${h_level}`}
                class="mb-2 font-extrabold text-xl"
            >
                <a
                    href="/courses/{course.slug}"
                    class="link focus:outline-none"
                >
                    {@html course.title}
                </a>
            </svelte:element>

            {@html course.lead}

        </div>
        <p class="mt-4 flex items-end {horizontal ? '' : 'flex-1'}">
            {#if course.price && price_only && !course.purchased}
                <span class="text-lg font-extrabold">{format_price(course.price.price, course.price.currency)}</span>
            {:else if course.price && !course.purchased}
                <PurchasePaddle
                    product={course}
                    style="small"
                    cta="Buy Now"
                    cta_free="Get for Free"
                    feature="list"
                    stopPropagation
                />
            {:else if course.purchased && !price_only}
                <a
                    class="btn btn-primary-outline"
                    href="/courses/{course.slug}/lessons"
                    on:click|stopPropagation>Continue Your Journey</a
                >
            {:else if !course.price}
                <a
                    class="btn btn-outline"
                    href="/courses/{course.slug}#form"
                    on:click|stopPropagation>Join Waitlist</a
                >
            {/if}
        </p>
    </Card>
{/each}
